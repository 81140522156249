import React from 'react';

import PortfolioItemLayout from '../../components/portfolio/PortfolioItemLayout';

import coverAqua from '../../assets/aqua/cover-aqua.png';

import a1 from '../../assets/aqua/analiza/a1.png';
import a2 from '../../assets/aqua/analiza/a2.png';
import a3 from '../../assets/aqua/analiza/a3.png';

import s1 from '../../assets/aqua/designsystem/s1.png';
import s2 from '../../assets/aqua/designsystem/s2.png';
import s3 from '../../assets/aqua/designsystem/s3.png';
import s4 from '../../assets/aqua/designsystem/s4.png';

import d1 from '../../assets/aqua/desktop/d1.png';
import d2 from '../../assets/aqua/desktop/d2.png';
import d3 from '../../assets/aqua/desktop/d3.png';

import m1 from '../../assets/aqua/mobile/m1.png';
import m2 from '../../assets/aqua/mobile/m2.png';
import m3 from '../../assets/aqua/mobile/m3.png';
import m4 from '../../assets/aqua/mobile/m4.png';

import u1 from '../../assets/aqua/ux/u1.png';
import u2 from '../../assets/aqua/ux/u2.png';
import u3 from '../../assets/aqua/ux/u3.png';
import u4 from '../../assets/aqua/ux/u4.png';
import u5 from '../../assets/aqua/ux/u5.png';

const Aqua = () => (
    <PortfolioItemLayout
        mainImgSrc={coverAqua}
        title="Aqua Areobik"
        subtitle="Redesign of the website for purchasing passes for Aquaaerobics."
        imgPosition="30%"
        // listing
        sector="Health/Sport"
        myRole="UX/UI designer"
        technology="Figma"
        projectTime="October 2023"
        // First section
        firstSectionTitle="Title"
        firstSectionDescription="Desc"
        // rest
        contentSections={[
            {
                title: 'Analysis of the existing site',
                description: `Creating a sitemap of the existing website and subpages
Analysis for accessibility and WCAG contrasts`,
                images: [
                    { original: a1 },
                    { original: a2 },
                    { original: a3 },
                ]
            },
            {
                title: 'UX',
                description: `Creation of Protopersona in response to whose needs further work was carried out - User Flow, Layout, Sitemap and adaptation of the existing site`,
                images: [
                    { original: u1 },
                    { original: u2 },
                    { original: u3 },
                    { original: u4 },
                    { original: u5 },
                ]
            },
            {
                title: 'Design System',
                description: `- Colors
- Typography
- Spacings
- Components & variants`,
                images: [
                    { original: s1 },
                    { original: s2 },
                    { original: s3 },
                    { original: s4 },
                ]
            },
            {
                title: 'Desktop Design',
                description: ``,
                images: [
                    { original: d1 },
                    { original: d2 },
                    { original: d3 },
                ]
            },
            {
                title: 'Mobile Design',
                description: ``,
                images: [
                    { original: m1 },
                    { original: m2 },
                    { original: m3 },
                    { original: m4 },
                ]
            },
        ]}
        nextLink="/portfolio/promyk"
    />
)

export default Aqua;